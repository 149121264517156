import React, { useState } from 'react'
import { init } from 'ityped'
import { useEffect, useRef } from 'react'
import './intro.scss'

const Intro = () => {
    const [futureMonth, setFutureMonth] = useState('');

    useEffect(() => {
        const today = new Date();
        const thirtyDaysLater = new Date(today);
        thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);

        const monthIndex = thirtyDaysLater.getMonth();
    }, []);

    const getMonthName = (monthIndex) => {
        const monthNames = ["січень", "лютий", "березень", "лвітень", "травень", "червень",
            "липень", "серпень", "вересень", "жовтень", "листопад", "грудень"];
        return monthNames[monthIndex];
    }


    return (


        <div className="intro" id="intro">
            <div className="container">
                <div className="photo">
                    <img src="assets/teacher9.png"
                        alt="Фото викладача" />
                </div>
            </div>
        </div>
    )
}

export default Intro;
